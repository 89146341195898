@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@400;600&family=Inter:wght@400;500;600;700&family=Space+Grotesk:wght@400;500;600&display=swap");

/* Theme */

:root {
  --main-color: #014491;
  --text-color: white;
  /*--hover-color: #df265e;*/
  --hover-color: #f49162;
  --sec-background-color: #014491;
  --title-color: #f49162 ;

  --font-primary: "Inter", sans-serif;
  --font-secondary: "Genty Demo", sans-serif;
  --font-mono: "Fira Code", monospace;
  --font-title: "Linguistics Pro", sans-serif;
}
