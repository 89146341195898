/* General */

* {
  box-sizing: border-box;
}

html {
  font-size: 10px;
  text-rendering: geometricPrecision;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  scroll-behavior: smooth;
}

html,
body {
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  position: relative; /* Ensures positioning context for the pseudo-element */
  background-attachment: fixed; /* Makes the background stay fixed when scrolling */
  color: var(--text-color);
  font-family: var(--font-primary);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
  margin: 0; /* Avoid unintended scroll gaps due to margins */
}

body::before {
  content: '';
  position: fixed; /* Fixed to ensure it stays with the background */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../src/assets/background.png');
  background-size: cover; /* Adjust to your needs (e.g., cover, contain) */
  background-position: center;
  background-attachment: fixed; /* Matches the main background */
  filter: opacity(0.9) blur(2px);
  z-index: -1; /* Ensures it's behind the content */
  pointer-events: none; /* Allows content interaction */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-secondary);
  font-weight: 600;
  color: var(--text-color);
}

h1,
h2,
h3 {
  margin-top: 3.2rem;
  margin-bottom: 1.6rem;
}

h4,
h5,
h6 {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
}

h1 {
  font-size: 3.2rem;
}

h2 {
  font-size: 2.8rem;
}

h3 {
  font-size: 2.4rem;
}

h4 {
  font-size: 2rem;
}

h5 {
  font-size: 1.6rem;
}

h6 {
  font-size: 1.4rem;
}

p {
  margin: 0 0 1.6rem;
}

strong {
  font-weight: 500;
}

small {
  font-size: 1.2rem;
}

blockquote {
  padding: 1.6rem 3.2rem;
  margin: 0 0 3.2rem;

  border-left: 8px solid #eee;

  font-size: 1.6rem;
  font-style: italic;
}

body,
button,
input,
select,
textarea {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

a {
  color: var(--text-color);
  text-decoration: none;
}

figure {
  margin: 0;
}
img {
  vertical-align: middle;
}

code,
pre {
  font-family: "Fira Code", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
  width: 100%;
}

code {
  color: var(--emerald);
}

#root {
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 640px) {
  .mobile-scroll-lock {
    overflow: hidden;
  }
}
